// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import HtmlContent from './HtmlContent';
import NavBar from './NavBar';

Amplify.configure(awsconfig);

const Home = ({ user }) => (
  <div>
    <HtmlContent url="https://testbucketforresecure.s3.us-west-2.amazonaws.com/index.html" />
  </div>
);

export default function App() {
  return (
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <Router>
          <NavBar user={user} signOut={signOut} />
          <main>
            <Routes>
              <Route path="/" element={<Home user={user} />} />
            </Routes>
          </main>
        </Router>
      )}
    </Authenticator>
  );
}
