// src/HtmlContent.js
import React, { useState, useEffect } from 'react';

const HtmlContent = ({ url }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    fetch(url)
      .then(response => response.text())
      .then(data => {
        setHtmlContent(data);
      })
      .catch(error => console.error('Error fetching HTML:', error));
  }, [url]);

  useEffect(() => {
    // Create a function to execute scripts in the HTML content
    const executeScripts = (html) => {
      const div = document.createElement('div');
      div.innerHTML = html;
      const scripts = div.getElementsByTagName('script');
      for (let script of scripts) {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        document.body.appendChild(newScript);
        document.body.removeChild(newScript);
      }
    };

    if (htmlContent) {
      executeScripts(htmlContent);
    }
  }, [htmlContent]);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default HtmlContent;
